import anime from "animejs";

export default function heroAnimation() {
  let tl = anime.timeline({
    easing: "easeInOutSine",
    duration: 0,
  });

  if (
    $("#js-index-page").length > 0 ||
    $("#js-about-page").length > 0 ||
    $("#js-pricing-page").length > 0
  ) {
    tl.add({
      targets: ".js-hero-title",
      opacity: [0, 1],
      delay: anime.stagger(0),
    }).add({
      targets: ".js-hero-content",
      opacity: [0, 1],
      delay: anime.stagger(0),
    });
  } else {
    tl.add({
      targets: "#js-hero-input",
      opacity: [0, 1],
    })
      .add({
        targets: ".js-hero-graphic",
        opacity: [0, 1],
      })
      .add({
        targets: "#js-hero-gradients",
        opacity: [0, 1],
      });
  }
}
