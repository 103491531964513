import Rellax from "rellax";

export default function initParallax() {
  window.onload = () => {
    let rellax = new Rellax(".rellax", {
      speed: -1.5,
      center: true,
    });
  };
}
