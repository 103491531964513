export default function tabs() {
  const $trigger = $(".js-toggle-tabs"),
    $handle = $("#js-filter-options-handle"),
    IS_ACTIVE = "is-active";

  $trigger.on("click", (e) => {
    if ($(e.currentTarget).hasClass("js-toggle-tabs--build")) {
      // "Build more, move faster" section
      e.preventDefault();

      let target = $(e.currentTarget).attr("href");

      $trigger.removeClass(IS_ACTIVE);
      $(e.currentTarget).addClass(IS_ACTIVE);

      $(".js-tab-build").hide();
      $(`.${target}`).fadeIn();

      if (target == "js-tab-build--defi") {
        $handle.css({
          transform: "translateX(133%)",
          width: "90px",
        });
      } else if (target == "js-tab-build--nfts") {
        $handle.css({
          transform: "translateX(234%)",
          width: "90px",
        });
      } else {
        $handle.css({
          transform: "translateX(0)",
          width: "120px",
        });
      }
    } else {
      // "A simple, powerful API" section
      $trigger.not(".js-toggle-tabs--build").removeClass(IS_ACTIVE);

      if (!$(e.currentTarget).hasClass(IS_ACTIVE)) {
        $(e.currentTarget).addClass(IS_ACTIVE);
      }

      const targetElementId = $(e.currentTarget).attr(
        "data-carousel-target-id"
      );
      if (!targetElementId) {
        return;
      }

      const targetElement = $(`#${targetElementId}`);
      targetElement.siblings().hide();
      targetElement.fadeIn();
    }
  });
}
