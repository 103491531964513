import "slick-carousel";

export default function initSliders() {
  if ($("#js-about-list").length > 0 && $(window).width()! <= 767) {
    $("#js-about-list").slick({
      dots: true,
      arrows: false,
      fade: true,
      infinite: true,
      slidesToShow: 1,
      cssEase: "ease-out",
      asNavFor: ".js-carousel-target-list",
      prevArrow: $("#js-news-prev"),
      nextArrow: $("#js-news-next"),
    });

    $(".js-carousel-target-list").slick({
      dots: false,
      arrows: false,
      fade: false,
      infinite: true,
      slidesToShow: 1,
      cssEase: "ease-out",
      asNavFor: "#js-about-list",
    });
  }
}
