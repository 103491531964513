import $ from "jquery";

// @ts-expect-error
window.jQuery = $;
// @ts-expect-error
window.$ = $;

import heroAnimation from "./modules/hero-animation";
import sticky from "./modules/sticky";
import tabs from "./modules/tabs";
import initParallax from "./modules/init-parallax";
import locker from "./modules/locker";
import toggleMenu from "./modules/toggle-menu";
import initSliders from "./modules/init-sliders";

heroAnimation();
sticky();
tabs();
locker();
toggleMenu();
initSliders();

initParallax();
