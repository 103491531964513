export default function locker() {
  const $trigger = $(".js-toggle-slide");

  $trigger.on("click", (e) => {
    let target = $(e.currentTarget).find(".js-toggle-target");
    $(e.currentTarget)
      .closest("#js-toggle-parent")
      .find(".js-toggle-target")
      .not(target)
      .slideUp();

    if ($(e.currentTarget).hasClass("is-active")) {
      $(e.currentTarget).removeClass("is-active");
    } else {
      $(e.currentTarget)
        .closest("#js-toggle-parent")
        .find(".js-toggle-slide")
        .removeClass("is-active");
      $(e.currentTarget).addClass("is-active");
    }

    target.stop(false, true).slideToggle();
  });
}
